import React, { useState, useEffect } from "react"
import {addEventToChildren} from "../utilities/props.js"

import {connect} from "react-redux"

function mapStateToProps(state, ownProps) {
  return {
    props: ownProps
  }
}

const Draggable = ({props, dispatch}) => {
    
  const [newChildren, setNewChildren] = useState(props.children)
  const [pos, setPos] = useState({
    isDragging: false,
    original: {
      x: 0,
      y: 0
    }, translate: {
      x: 0,
      y: 0
    }, lastTranslate: {
      x: 0,
      y: 0
    }
  })

  const componentWillUnmount = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  }
  
  const handleMouseMove = (e) => {

    // console.log("currently at: " + e.screenX + ", " + e.screenY);
    // console.log("screen: " + window.innerHeight + ", " + window.innerWidth);

    if (e.clientX > 0 && e.clientY > 0 && 
        e.clientX < window.innerWidth && e.clientY < window.innerHeight) {
      setPos(prevPos => ({
        ...prevPos, 
        translate: {
          x: e.clientX - prevPos.original.x + prevPos.lastTranslate.x,
          y: e.clientY - prevPos.original.y + prevPos.lastTranslate.y
        }
      }));
    }
    
  }

  const handleMouseUp = (e) => {
    setPos(prevPos => ({
      ...prevPos,
      lastTranslate: {
        x: prevPos.translate.x,
        y: prevPos.translate.y
      },
      isDragging: false
    }));
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  }

  const handleMouseDown = ({clientX, clientY}) => {

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    setPos(prevPos => ({
      ...prevPos,
      original: {
        x: clientX,
        y: clientY
      },
      isDragging: true
    }));
  }

  const handleTouchMove = (e) => {
    // console.log("touch move")

    let touch = e.touches[0];

    setPos(prevPos => ({
      ...prevPos, 
      translate: {
        x: touch.clientX - prevPos.original.x + prevPos.lastTranslate.x,
        y: touch.clientY - prevPos.original.y + prevPos.lastTranslate.y
      }
    }));
  }

  const handleTouchEnd = (e) => {
    // console.log("touch end")

    setPos(prevPos => ({
      ...prevPos,
      lastTranslate: {
        x: prevPos.translate.x,
        y: prevPos.translate.y
      },
      isDragging: false
    }));
    e.target.removeEventListener('touchmove', handleMouseMove);
    e.target.removeEventListener('touchend', handleMouseUp);
  }

  const handleTouchStart = (e) => {

    e.preventDefault();

    e.target.addEventListener('touchmove', handleTouchMove);
    e.target.addEventListener('touchend', handleTouchEnd);

    let touch = e.touches[0];
    // console.log(touch)

    setPos(prevPos => ({
      ...prevPos,
      original: {
        x: touch.clientX,
        y: touch.clientY
      },
      isDragging: true
    }));
  }

  // componentDidMount & componentWillUnmount
  useEffect(() => {
    if (props.hasHeader) {
        setNewChildren(addEventToChildren(addEventToChildren(props.children, "header", "onMouseDown", handleMouseDown), "header", "onTouchStart", handleTouchStart))
    } else {
        setNewChildren(props.children);
    }

    return componentWillUnmount();
  }, [props.children])
 
  return (
    <div
      style={{
        msTransform: `translate(${pos.translate.x}px, ${pos.translate.y}px)`, 
        WebkitTransform: `translate(${pos.translate.x}px, ${pos.translate.y}px)`,
        transform: `translate(${pos.translate.x}px, ${pos.translate.y}px)`,
        position: "relative",
        display: props.display,
        width: props.width,
        verticalAlign: props.verticalAlign}}
        onMouseDown={props.hasHeader ? undefined : handleMouseDown}
        onContextMenu={props.hasHeader ? undefined : handleMouseDown}
        onTouchStart={props.hasHeader ? undefined : handleTouchStart}
    >
      {newChildren}
    </div>
  )
}

export default connect(mapStateToProps, null)(Draggable);