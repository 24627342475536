import React, {useEffect} from "react"
import styles from "../styles/navbar.module.scss"
import FolderIcon from "./folder-icon"
import {connect} from "react-redux"

function mapStateToProps(state, ownProps) {
  return {
    isHomeSelected: state.app.isHomeSelected,
    isExperienceSelected: state.app.isExperienceSelected,
    isProjectsSelected: state.app.isProjectsSelected,
    isErrorSelected: state.app.isErrorSelected,
    isBlogSelected: state.app.isBlogSelected,
    isToggleReloadOn: state.app.isToggleReloadOn,
    props: ownProps
  }
}

const NavBar = ({isHomeSelected, isExperienceSelected, isProjectsSelected, isErrorSelected, isBlogSelected, isToggleReloadOn, props, dispatch}) => {

    // componentDidMount & componentWillUnmount
    useEffect(() => {
        console.log("state: " + isHomeSelected +  isExperienceSelected + isProjectsSelected + isErrorSelected + isBlogSelected)
        console.log("toggle reload: " + isToggleReloadOn)
    }, [isHomeSelected, isExperienceSelected, isProjectsSelected, isErrorSelected, isBlogSelected, isToggleReloadOn])

  return (<div className={styles.navbar}>
    <div className={styles.folderIconsContainer}>
    <FolderIcon link="/" selected={isHomeSelected} homeFolder={true}>
      Home
    </FolderIcon>
    <FolderIcon link="/experience" tabColor="green" selected={isExperienceSelected}>
      Experience
    </FolderIcon>
    <FolderIcon link="/projects" tabColor="red" selected={isProjectsSelected}>
      Projects
    </FolderIcon>
    <FolderIcon link="/blog" tabColor="purple" selected={isBlogSelected}>
      Blog
    </FolderIcon>
    </div>
  </div>)
}

export default connect(mapStateToProps, null)(NavBar);