import React, {useState, useEffect} from "react"
import styles from "../styles/folder-icon.module.scss"
import Draggable from "./draggable"

import {connect} from "react-redux"
import {toggleHome, toggleExperience, toggleProjects, toggleError, toggleBlog} from "../state/app"

function mapStateToProps(state, ownProps) {
  return {
    isHomeSelected: state.app.isHomeSelected,
    isExperienceSelected: state.app.isExperienceSelected,
    isProjectsSelected: state.app.isProjectsSelected,
    isBlogSelected: state.app.isBlogSelected,
    isToggleReloadOn: state.app.isToggleReloadOn,
    props: ownProps
  }
}

const FolderIcon =  ({isHomeSelected, isExperienceSelected, isProjectsSelected, isBlogSelected, isToggleReloadOn, props, dispatch}) => {

  const threshold = 1;

  let pos = {x: 0, y: 0}
  let [openImage, setOpenImage] = useState(<svg
    className={styles.folderIcon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 191 159"
  >
    <title>{props.children} Folder</title>
    <g id="open">
      <path
        className={styles.folderOutline}
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      />
      <path
        className={styles[props.tabColor + "Tab"]}
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      />
      <path
        className={styles.folderOutline}
        d="M172,43H44c-6.6,0-10.5,4.5-12,12s-12.5,65.5-13,72,5.4,12,12,12H159c6.6,0,10.5-3.5,12-12s11.5-64.5,13-72S178.6,43,172,43Z"
      />
      <path
        className={styles.folderBase}
        d="M172,43H44c-6.6,0-10.5,4.5-12,12s-12.5,65.5-13,72,5.4,12,12,12H159c6.6,0,10.5-3.5,12-12s11.5-64.5,13-72S178.6,43,172,43Z"
      />
    </g>
  </svg>)
  let [closedImage, setClosedImage] = useState(<svg
    className={styles.folderIcon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 191 159"
  >
    <defs></defs>
    <title>{props.children} Folder</title>
    <g id="closed">
      <path
        className={styles.folderOutline}
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      />
      <path
        className={styles[props.tabColor + "Tab"]}
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      />
      <rect
        className={styles.folderOutline}
        x="19"
        y="43"
        width="152"
        height="96"
        rx="12"
        ry="12"
      />
      <rect
        className={styles.folderBase}
        x="19"
        y="43"
        width="152"
        height="96"
        rx="12"
        ry="12"
      />
    </g>
  </svg>
  )

  useEffect(() => {
    if (props.homeFolder) {
      setOpenImage(
        <svg className={styles.homeLogoTop} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191 159">
          <title>{props.children} Folder</title>
          <g id="Layer_3" data-name="Layer 3"><path className={styles.homeLogo} d="M15.39,92.8A231.4,231.4,0,0,1,6.84,67.14c-2-8-3.61-17,1.89-18.33s5.33,1.33,6.66,8,2.17,9.39,2.67,9.33,1-2.83,1.33-5.33,0-10.66,2.67-13.33,4.55-.11,6.66,1.33,1.33,8-1.33,16c-1.33,2.67,2.44,3.78,4,4.44s4.55,1.78,8.33,3.33S46,76.8,46,79.47s-1,5.66-4,5.33-14.66-8-16-9.33-4-2.67-4,0,1.33,9.33,2.67,13.33,3.83,8.33,0,9.33S18.06,99.46,15.39,92.8Z"/>
            <path className={styles.homeLogo} d="M39.38,58c-1.79-5.37-3.17-11.33-2-13.5s2.83-2.67,4.33-2.33,2.83,3.17,3.67,3.33.67-1.22,2-3.17,3.67-3.67,8.5-2.83,5,2.94,5,4.33-1.72,3.28-3.17,2.67-4.67-.11-5.5,1.67-2.05,7-1.17,9.5,3.67,6.5,4.17,9.5S55.88,72,52.55,73s-7.33-.67-9.33-4.83A79.09,79.09,0,0,1,39.38,58Z"/>
            <path className={styles.homeLogo} d="M62.21,58.31c.25-3.17.5-6.16,2.17-7.66a6.53,6.53,0,0,1,4.33-2c1,.08,3.83,0,4,4.17s.67,16.91.5,20.33-1.83,4.33-2.83,4.5-4.5.67-6.33-.83S62,61.47,62.21,58.31Z"/>
            <path className={styles.homeLogo} d="M63.54,39.56c-1-.88-1.92-4.17-.67-5.58s2.83-2.33,4-2.25,3.92.83,4.08,2.58,1.25,2.17.92,3.08-1.08,2.67-2.08,2.92S65.62,41.31,63.54,39.56Z"/>
            <path className={styles.homeLogo} d="M76.27,39.42c1.31-3.23,3.75-5.17,6.16-5.58s5.33-.75,7.5,0,4.92,2.25,3.17,4.67-3.42,2.58-4.42,1.92A1.85,1.85,0,0,0,86.1,42.5c.5,1.67,1.5,2.42,3.83,3.42s7.83,3.33,10.5,6.5A11.72,11.72,0,0,1,103,62.08a12.71,12.71,0,0,1-5.33,8.16c-2.75,2-8.16,4.92-13.08,3.5S75.43,68.66,75.77,66s1.33-5,4.08-4.5,3.58,2.5,6.5,2.92,5.58-4.17,4.5-5.75-7-5.42-10.5-7.08S75,42.5,76.27,39.42Z"/>
            <path className={styles.homeLogo} d="M98.75,39.86c-.19-3.42,1.08-4.92,3.5-5s5.66-.17,5.83-.75a67.59,67.59,0,0,0,1-7.66c0-1.79-.57-5.92,1.6-5.92s5.5,1,6,3.83-.08,7.83.25,8.5,1.17,1.17,2.75,1.25a3.59,3.59,0,0,1,3.58,3.75c-.08,2.58-.5,4.92-2.5,4.75s-2.92-.33-3.25.25-.33,9.91.83,14,1.42,6,5.08,5.91,5.42.25,5.58,2.83-.5,5-4,5.25-7.58.83-11-1.75-6-6.16-6.16-14,.5-10.75-.25-11.08-2.08-.17-3.92-.17S99,44.44,98.75,39.86Z"/>
            <path className={styles.homeLogo} d="M133.93,29.19c3.12-2.29,9-2.6,15.08,0s5.62,11.65,4.58,14.35-3.85,3.33-6.24,3.22a93.85,93.85,0,0,1-9.46-1.35c-1.56-.31-2.29-.83-2.81,1.25s1.56,5.1,3,5.82a6.86,6.86,0,0,0,6-.21,5.34,5.34,0,0,1,5.41.42c1.25.73,1.25,1.77.73,3.85s-1.66,3.43-4.89,4.26-8.53-.21-14.25-2.39-6.32-8.87-5.72-14A21.81,21.81,0,0,1,133.93,29.19Zm12,9.26c1,.1,1.14-1.56.94-2.39s-3.64-2.18-6.66-2.08-3.74,2.91-2.39,3.43A36.63,36.63,0,0,0,145.89,38.45Z"/>
            <path className={styles.homeLogo} d="M161.25,39.4c-2.1.42-4.26,1.25-4.06,5.72s.42,16.12,1.66,17.78,2,2.91,4.47,2.39,3.74-1,4.06-3.33a74.93,74.93,0,0,1,1.87-9.88,38.55,38.55,0,0,1,1.66-5.51c.42-.83,2.18-3,2.29-.83a75.35,75.35,0,0,1-.21,9.88,5.16,5.16,0,0,0,3.54,6c3.64,1.25,4.26.73,5.51-1.87a48.22,48.22,0,0,0,3.43-15.39c0-4.89-.21-8.42-3.85-9.46a21.41,21.41,0,0,0-10.82-.31c-2.7.83-3.22,4.47-4.58,4.37A18.12,18.12,0,0,0,161.25,39.4Z"/>
            <path className={styles.homeLogo} d="M31.61,96.46c.19,4,2.08,14.56,2.46,18.9s.57,19.66,3.4,19.28,6.24-1.32,7.18-5.48-.38-9.64-.38-14.56,1.13-5.67,1.89-5.1,7,6.62,10,12.29,4.35,5.67,6,4.16,2.27-1.51,4.16-3.59,1.51-4.16-.95-7.75-12.67-10.59-13-12.1,4.35-7.37,5.86-10.59,3.4-8.13,2.27-9.26-5.67-3-7.94-.76-8.51,12.67-9.83,12.67.57-4.73-2.08-6.62-6.62-2.46-8.88-1.32S31.42,92.49,31.61,96.46Z"/>
            <path className={styles.homeLogo} d="M63.36,98.54c.81,5.45,4.16,16.82,6.24,17.2s3.4.57,6-2.65,3-5.1,4.73-4.54,3.78,3.78,6.62,4.73,5.48,1.32,6.24-.76,1.7-14.74-1.13-24.2-5.48-10-7.37-9.07-4.54,4.35-3.78,6.24,2.65,8.51,1.7,9.83-2.27-1.13-3.59-.76-2.27,4.91-3.78,4.73-2.46-4-3.21-6.24-1.7-5.86-4.91-3.78S62.61,93.43,63.36,98.54Z"/>
            <path className={styles.homeLogo} d="M102.49,81.33c6.74-.67,12.1,1.51,13.8,4s2.65,7.18,1.7,13.42-4.35,7.37-8.51,8.88-7.18.19-10.4-2.65-3.85-10.79-2.84-15.88C96.82,86.25,98.71,81.71,102.49,81.33Zm2.84,10.4c-1,2-1.7,6.62-.57,7s2.46,1.13,3.21-1.32a7.47,7.47,0,0,0-.19-5.48C107.22,90.6,105.9,90.6,105.33,91.73Z"/>
            <path className={styles.homeLogo} d="M118.75,80c.49,7.39,2.84,18,4.91,19.28s4.54,2.08,6.43.57c1.13-1.13,0-2.46-.76-5.1a28.36,28.36,0,0,1-.95-9.64c.38-2.46,1.13-3.59,3-3.78s1.13,2.46,1.13,4.16-.95,9.26,2.27,10.77,4.54.95,5.29-.19S142,90.79,141.43,84s-2.08-10-5.67-10.4-6,.57-7.18,2.08-2.08,2.27-3,.57-3-1.7-4.73-1.32S118.56,77.18,118.75,80Z"/>
            <path className={styles.homeLogo} d="M153.82,70.69c4.73-2.46,10.21-.57,11.15.19s1.7,2.08,2.84,1.51,1.51-1.89,4.35-1.13,3.72,3,3.21,8.13-3.5,17.77-7.8,26.08-8.83,17-13.94,18.91-11.91,0-14.93-4-1.89-10.4-1.51-12.48,3.78-4.35,6-3.59-1.32,8.32,4.35,11.34,10.69-11.56,11.06-12.5,1.16-3.38-.92-3.75-4.29-.57-8.45-4.16-4.65-10.81-3.21-15.12A15.26,15.26,0,0,1,153.82,70.69Zm9.45,13.61c1-2.47-2.27-5.29-4.16-3.21s-3.59,6-1.32,6.62S161.95,87.51,163.27,84.3Z"/>
          </g>
        </svg>)
      setClosedImage(
        <svg className={styles.homeLogoTop} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191 159">
          <title>{props.children} Folder</title>
          <g id="Layer_3" data-name="Layer 3"><path className={styles.homeLogoClosed} d="M15.39,92.8A231.4,231.4,0,0,1,6.84,67.14c-2-8-3.61-17,1.89-18.33s5.33,1.33,6.66,8,2.17,9.39,2.67,9.33,1-2.83,1.33-5.33,0-10.66,2.67-13.33,4.55-.11,6.66,1.33,1.33,8-1.33,16c-1.33,2.67,2.44,3.78,4,4.44s4.55,1.78,8.33,3.33S46,76.8,46,79.47s-1,5.66-4,5.33-14.66-8-16-9.33-4-2.67-4,0,1.33,9.33,2.67,13.33,3.83,8.33,0,9.33S18.06,99.46,15.39,92.8Z"/>
            <path className={styles.homeLogoClosed} d="M39.38,58c-1.79-5.37-3.17-11.33-2-13.5s2.83-2.67,4.33-2.33,2.83,3.17,3.67,3.33.67-1.22,2-3.17,3.67-3.67,8.5-2.83,5,2.94,5,4.33-1.72,3.28-3.17,2.67-4.67-.11-5.5,1.67-2.05,7-1.17,9.5,3.67,6.5,4.17,9.5S55.88,72,52.55,73s-7.33-.67-9.33-4.83A79.09,79.09,0,0,1,39.38,58Z"/>
            <path className={styles.homeLogoClosed} d="M62.21,58.31c.25-3.17.5-6.16,2.17-7.66a6.53,6.53,0,0,1,4.33-2c1,.08,3.83,0,4,4.17s.67,16.91.5,20.33-1.83,4.33-2.83,4.5-4.5.67-6.33-.83S62,61.47,62.21,58.31Z"/>
            <path className={styles.homeLogoClosed} d="M63.54,39.56c-1-.88-1.92-4.17-.67-5.58s2.83-2.33,4-2.25,3.92.83,4.08,2.58,1.25,2.17.92,3.08-1.08,2.67-2.08,2.92S65.62,41.31,63.54,39.56Z"/>
            <path className={styles.homeLogoClosed} d="M76.27,39.42c1.31-3.23,3.75-5.17,6.16-5.58s5.33-.75,7.5,0,4.92,2.25,3.17,4.67-3.42,2.58-4.42,1.92A1.85,1.85,0,0,0,86.1,42.5c.5,1.67,1.5,2.42,3.83,3.42s7.83,3.33,10.5,6.5A11.72,11.72,0,0,1,103,62.08a12.71,12.71,0,0,1-5.33,8.16c-2.75,2-8.16,4.92-13.08,3.5S75.43,68.66,75.77,66s1.33-5,4.08-4.5,3.58,2.5,6.5,2.92,5.58-4.17,4.5-5.75-7-5.42-10.5-7.08S75,42.5,76.27,39.42Z"/>
            <path className={styles.homeLogoClosed} d="M98.75,39.86c-.19-3.42,1.08-4.92,3.5-5s5.66-.17,5.83-.75a67.59,67.59,0,0,0,1-7.66c0-1.79-.57-5.92,1.6-5.92s5.5,1,6,3.83-.08,7.83.25,8.5,1.17,1.17,2.75,1.25a3.59,3.59,0,0,1,3.58,3.75c-.08,2.58-.5,4.92-2.5,4.75s-2.92-.33-3.25.25-.33,9.91.83,14,1.42,6,5.08,5.91,5.42.25,5.58,2.83-.5,5-4,5.25-7.58.83-11-1.75-6-6.16-6.16-14,.5-10.75-.25-11.08-2.08-.17-3.92-.17S99,44.44,98.75,39.86Z"/>
            <path className={styles.homeLogoClosed} d="M133.93,29.19c3.12-2.29,9-2.6,15.08,0s5.62,11.65,4.58,14.35-3.85,3.33-6.24,3.22a93.85,93.85,0,0,1-9.46-1.35c-1.56-.31-2.29-.83-2.81,1.25s1.56,5.1,3,5.82a6.86,6.86,0,0,0,6-.21,5.34,5.34,0,0,1,5.41.42c1.25.73,1.25,1.77.73,3.85s-1.66,3.43-4.89,4.26-8.53-.21-14.25-2.39-6.32-8.87-5.72-14A21.81,21.81,0,0,1,133.93,29.19Zm12,9.26c1,.1,1.14-1.56.94-2.39s-3.64-2.18-6.66-2.08-3.74,2.91-2.39,3.43A36.63,36.63,0,0,0,145.89,38.45Z"/>
            <path className={styles.homeLogoClosed} d="M161.25,39.4c-2.1.42-4.26,1.25-4.06,5.72s.42,16.12,1.66,17.78,2,2.91,4.47,2.39,3.74-1,4.06-3.33a74.93,74.93,0,0,1,1.87-9.88,38.55,38.55,0,0,1,1.66-5.51c.42-.83,2.18-3,2.29-.83a75.35,75.35,0,0,1-.21,9.88,5.16,5.16,0,0,0,3.54,6c3.64,1.25,4.26.73,5.51-1.87a48.22,48.22,0,0,0,3.43-15.39c0-4.89-.21-8.42-3.85-9.46a21.41,21.41,0,0,0-10.82-.31c-2.7.83-3.22,4.47-4.58,4.37A18.12,18.12,0,0,0,161.25,39.4Z"/>
            <path className={styles.homeLogoClosed} d="M31.61,96.46c.19,4,2.08,14.56,2.46,18.9s.57,19.66,3.4,19.28,6.24-1.32,7.18-5.48-.38-9.64-.38-14.56,1.13-5.67,1.89-5.1,7,6.62,10,12.29,4.35,5.67,6,4.16,2.27-1.51,4.16-3.59,1.51-4.16-.95-7.75-12.67-10.59-13-12.1,4.35-7.37,5.86-10.59,3.4-8.13,2.27-9.26-5.67-3-7.94-.76-8.51,12.67-9.83,12.67.57-4.73-2.08-6.62-6.62-2.46-8.88-1.32S31.42,92.49,31.61,96.46Z"/>
            <path className={styles.homeLogoClosed} d="M63.36,98.54c.81,5.45,4.16,16.82,6.24,17.2s3.4.57,6-2.65,3-5.1,4.73-4.54,3.78,3.78,6.62,4.73,5.48,1.32,6.24-.76,1.7-14.74-1.13-24.2-5.48-10-7.37-9.07-4.54,4.35-3.78,6.24,2.65,8.51,1.7,9.83-2.27-1.13-3.59-.76-2.27,4.91-3.78,4.73-2.46-4-3.21-6.24-1.7-5.86-4.91-3.78S62.61,93.43,63.36,98.54Z"/>
            <path className={styles.homeLogoClosed} d="M102.49,81.33c6.74-.67,12.1,1.51,13.8,4s2.65,7.18,1.7,13.42-4.35,7.37-8.51,8.88-7.18.19-10.4-2.65-3.85-10.79-2.84-15.88C96.82,86.25,98.71,81.71,102.49,81.33Zm2.84,10.4c-1,2-1.7,6.62-.57,7s2.46,1.13,3.21-1.32a7.47,7.47,0,0,0-.19-5.48C107.22,90.6,105.9,90.6,105.33,91.73Z"/>
            <path className={styles.homeLogoClosed} d="M118.75,80c.49,7.39,2.84,18,4.91,19.28s4.54,2.08,6.43.57c1.13-1.13,0-2.46-.76-5.1a28.36,28.36,0,0,1-.95-9.64c.38-2.46,1.13-3.59,3-3.78s1.13,2.46,1.13,4.16-.95,9.26,2.27,10.77,4.54.95,5.29-.19S142,90.79,141.43,84s-2.08-10-5.67-10.4-6,.57-7.18,2.08-2.08,2.27-3,.57-3-1.7-4.73-1.32S118.56,77.18,118.75,80Z"/>
            <path className={styles.homeLogoClosed} d="M153.82,70.69c4.73-2.46,10.21-.57,11.15.19s1.7,2.08,2.84,1.51,1.51-1.89,4.35-1.13,3.72,3,3.21,8.13-3.5,17.77-7.8,26.08-8.83,17-13.94,18.91-11.91,0-14.93-4-1.89-10.4-1.51-12.48,3.78-4.35,6-3.59-1.32,8.32,4.35,11.34,10.69-11.56,11.06-12.5,1.16-3.38-.92-3.75-4.29-.57-8.45-4.16-4.65-10.81-3.21-15.12A15.26,15.26,0,0,1,153.82,70.69Zm9.45,13.61c1-2.47-2.27-5.29-4.16-3.21s-3.59,6-1.32,6.62S161.95,87.51,163.27,84.3Z"/>
          </g>
        </svg>
      )
    }
  }, [])

  const toggleOpened = (folderName) => {
    switch (folderName.toLowerCase()) {
      case "home":
        dispatch(toggleHome(!isHomeSelected))
        dispatch(toggleExperience(false))
        dispatch(toggleProjects(false))
        dispatch(toggleError(false))
        dispatch(toggleBlog(false))
        return;
      case "experience":
        dispatch(toggleHome(false))
        dispatch(toggleExperience(!isExperienceSelected))
        dispatch(toggleProjects(false))
        dispatch(toggleError(false))
        dispatch(toggleBlog(false))
        return;
      case "projects":
        dispatch(toggleHome(false))
        dispatch(toggleExperience(false))
        dispatch(toggleProjects(!isProjectsSelected))
        dispatch(toggleError(false))
        dispatch(toggleBlog(false))
        return;
      case "blog":
        dispatch(toggleHome(false))
        dispatch(toggleExperience(false))
        dispatch(toggleProjects(false))
        dispatch(toggleBlog(!isBlogSelected))
        dispatch(toggleError(false))
        return;
      default: 
        return;
    }
  }

  const handleMouseClick = (e) => {
    e.preventDefault();
    pos = {
        x: e.screenX,
        y: e.screenY
    };
  }

  const withinThreshold = (newPos, origPos) => {
    if (newPos >= origPos) {
      return (newPos - origPos <= threshold);
    } else {
      return (origPos - newPos <= threshold);
    }
  }

  const handleMouseUp = (e) => {
    e.preventDefault();

    if (withinThreshold(e.screenX, pos.x) && withinThreshold(e.screenY, pos.y)) {

      // navigate(props.link)
      // return;

      // Reloads the entire page instead of modifying display on windows
      if (isToggleReloadOn) {
        window.location = props.link;
        return;
      }

      toggleOpened(props.children);
      
      switch (props.children.toLowerCase()) {
        case "home":
          if (!isHomeSelected)
            window.history.pushState("", props.title, props.link);
          return;
        case "experience":
          if (!isExperienceSelected)
            window.history.pushState("", props.title, props.link);
          return;
        case "projects":
          if (!isProjectsSelected)
            window.history.pushState("", props.title, props.link);
          return;
        case "blog":
          if (!isBlogSelected)
            window.history.pushState("", props.title, props.link);
          return;
        default: 
          window.location = props.link;
          return;
      }
    }
  }

  // Render
  if (props.selected) {
    return (
      <Draggable>
        <div className={styles.wholeFolder}>
      <div 
        className={styles.link} 
        role="button" 
        draggable="false"
        onMouseDown={handleMouseClick}
        onMouseUp={handleMouseUp}>
          {openImage}
        <div className={styles.folderName}>{props.children}</div>
      </div>
      </div>
      </Draggable>
    )
  } else {
    return (
      <Draggable> 
        <div className={styles.wholeFolder}>
        <div 
        className={styles.link}
        role="button"
        draggable="false"
        onMouseDown={handleMouseClick}
        onMouseUp={handleMouseUp}
      >
        {closedImage}
        <div className={styles.folderName}>{props.children}</div>
      </div>
      </div>
      </Draggable>
    )
  }
  
  
}


export default connect(mapStateToProps, null)(FolderIcon);