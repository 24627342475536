import React, {useEffect} from "react"
import styles from "../styles/footer.module.scss"

const Footer = () => {

    var d = new Date();

  return (<div className={styles.footer}>
    Made with ♥ by Kristen Kwong &copy; {d.getFullYear()}
  </div>)
}

export default Footer;