import React, { useState } from "react"
import styles from "../styles/rwindow.module.scss"
import Draggable from "./draggable"

import {connect} from "react-redux"
import {toggleHome, toggleExperience, toggleProjects, toggleError, toggleBlog} from "../state/app"

function mapStateToProps(state, ownProps) {
  return {
    isHomeSelected: state.app.isHomeSelected,
    isExperienceSelected: state.app.isExperienceSelected,
    isProjectsSelected: state.app.isProjectsSelected,
    isErrorSelected: state.app.isErrorSelected,
    props: ownProps
  }
}

const RWindow = ({props, dispatch}) => {

  const [state, setState] = useState({
    windowDisplay: "block" // default is none to prevent div ghost on load
  })


  const hideWindow = () => {

    switch(props.name) {
      case "home":
        dispatch(toggleHome(false));
        return;
      case "experience":
        dispatch(toggleExperience(false));
        return;
      case "projects":
        dispatch(toggleProjects(false));
        return;
      case "error":
        dispatch(toggleError(false));
        return;
      case "blog":
        dispatch(toggleBlog(false));
        return;
      default: 
        setState({windowDisplay: "none"});
        dispatch(toggleBlog(false));
        return;
    }
  }

  return (
    
    <Draggable hasHeader={true}>
    <div
      style={{ display: state.windowDisplay, zIndex: props.zIndex}}
      className={`${styles.windowContainer} ${props.parentClass}`}
    >
      <div className={styles.wordsContainerTopper}>
        <span className={`${styles.headerContainer} header`}></span>
        <span className={styles.dotContainer}>
          <span
            onClick={hideWindow}
            className={`${styles.dot} ${styles.pink}`}
          ></span>
          <span className={styles.dot}></span>
          <span className={styles.dot}></span>
        </span>
      </div>
      <div className={styles.wordsContainer}>
        <div className={styles.internalContainer}>
          <div className={styles.title}>{props.title}</div>
          <span>{props.children}</span>
        </div>
      </div>
      <div className={styles.windowBottom}>
        
      </div>
    </div>
  </Draggable>
  
  )
}

export default connect(mapStateToProps, null)(RWindow);