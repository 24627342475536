
// Searches the React element and adds the event to the one with the className
export function addEventToChildren(propsChildren, className, mouseEvent, eventHandler) {

    // console.log('begin search')
    // console.log(propsChildren);

    if (typeof propsChildren !== 'object') {
        return propsChildren;
    }

    if (Array.isArray(propsChildren)) {
        // console.log("more than one child");

        let newChildren = [];

        for (let i = 0; i < propsChildren.length; i++) {
            
            if (propsChildren[i].props && propsChildren[i].props.className && propsChildren[i].props.className.split(" ").includes(className)) {
                // console.log("found child with class " + className)
                // add the mouse event and stop search
                let newChild = {
                    ...propsChildren[i],
                    props: {
                        ...propsChildren[i].props,
                        [mouseEvent]: eventHandler
                    }
                };
                newChildren.push(newChild);
            } else {
                // console.log("child, not the right class");
                if (propsChildren[i].props && propsChildren[i].props.children) {
                    // console.log("more children to process");
                    newChildren.push({
                        ...propsChildren[i],
                        props: {
                            ...propsChildren[i].props,
                            children: addEventToChildren(propsChildren[i].props.children, className, mouseEvent, eventHandler)
                        }
                    });
                } else {
                    // console.log("no more children")
                    newChildren.push(propsChildren[i])
                }
            }
        }
        // console.log("new children");
        // console.log(newChildren);
        return newChildren;
    } else {

        let newChildren = {};
        if (propsChildren.props && propsChildren.props.className && propsChildren.props.className.split(" ").includes(className)) {
            // add mouse event and end
            newChildren = {
                ...propsChildren,
                props: {
                    ...propsChildren.props,
                    [mouseEvent]: eventHandler
                }
            };
        } else {
            newChildren = {
                ...propsChildren,
                props: {
                    ...propsChildren.props,
                    children: addEventToChildren(propsChildren.props.children, className, mouseEvent, eventHandler)
                }
            }
        }
        // console.log(newChildren);
        return newChildren;
       
    }
    
}